import React from 'react';
import './Modal.css';

const Modal = ({ cuisine, dishes, onClose }) => {
    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={onClose}>X</button>
                <h2>Example {cuisine} Dishes</h2>
                <ul>
                    {dishes.map((dish, index) => (
                        <li key={index}>
                            <h4>{dish.name}</h4>
                            <p>{dish.description}</p>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Modal;
