import React, { useState, useEffect } from 'react';
import './Menu.css';
import Modal from './Modal';

const menuItems = {
    'Italian': [
        { name: 'Short Rib Ragu', description: 'Slow-cooked short ribs with a tomato and red wine sauce' },
        { name: 'Handmade Pasta', description: 'Deliciously fresh, handmade pasta in a variety of styles and flavors' },
        { name: 'Chicken Piccata', description: 'Chicken breast sautéed with a lemon caper sauce over linguine' },
        { name: 'Carbonara', description: 'Classic Roman pasta with eggs, Pecorino cheese, pancetta, and black pepper' },
        { name: 'Cacio e Pepe', description: 'Simple pasta dish with Pecorino Romano cheese and black pepper' }
    ],
    'Asian Fusion': [
        { name: 'Tuna Poke', description: 'Ahi tuna, sesame, soy, wasabi, sriracha, rice crackers' },
        { name: 'Yellowfin Crudo', description: 'Fried cumin, lemon vinaigrette, jalapeno, buttermilk lane, chive oil' },
        { name: 'Vietnamese Summer Rolls', description: 'Rice paper rolls filled with shrimp, herbs, vermicelli, and vegetables' },
        { name: 'Tom Yum Linguini', description: 'Linguini in a spicy Thai broth with shrimp and mushrooms' }
    ],
    'Mediterranean': [
        { name: 'Rack of Lamb Chops', description: 'Mediterranean style with a rosemary and thyme rub' },
        { name: 'Clams with Coriander and Lemon', description: 'Steamed clams served with a coriander butter sauce' },
        { name: 'Cedar Plank Salmon', description: 'Salmon fillet grilled on a cedar plank for a smoky flavor' }
    ],
    'French': [
        { name: 'Duck Breast with Lemongrass Demi Glaze', description: 'Seared duck breast with a tangy lemongrass sauce' },
        { name: 'Moules Marinières', description: 'French mussels steamed in white wine with garlic and herbs' },
        { name: 'Roast Chicken Picatta', description: 'Pan-fried chicken breasts served with a lemon caper sauce' }
    ],
    'American': [
        { name: 'Grilled Duroc Pork Chop', description: 'Thick-cut pork chops with a spiced apple compote' },
        { name: 'Skin on Chicken Thighs', description: 'Boneless, crispy skin, special spice blend' },
        { name: 'Grilled Shrimp', description: 'Marinated shrimp grilled with garlic and herbs' }
    ],
    'Latin American': [
        { name: 'Pico de Gallo', description: 'Fresh chopped tomato, onion, cilantro, lime juice, and chili peppers' },
        { name: 'Guacamole', description: 'Ripe avocados mashed with onions, tomatoes, cilantro, and spices' },
        { name: 'Mexican Mole Ravioli', description: 'Ravioli filled with mole sauce and chicken, topped with sesame seeds' }
    ]
};

const cuisines = [
    {
        name: 'Italian',
        description: 'Indulge in the rich and diverse flavors of Italy. From handmade pasta to slow-cooked ragus, our Italian dishes are crafted to bring the warmth of a traditional Italian kitchen to your table.'
    },
    {
        name: 'Asian Fusion',
        description: 'Experience a vibrant mix of Asian flavors. Our Asian Fusion menu combines the best of various cuisines to tantalize your taste buds.'
    },
    {
        name: 'Mediterranean',
        description: 'Savor the fresh and aromatic tastes of the Mediterranean. Our Mediterranean options promise a journey through the sun-kissed coasts and rich culinary heritage.'
    },
    {
        name: 'French',
        description: 'Delight in the elegance and sophistication of French cuisine.'
    },
    {
        name: 'American',
        description: 'Enjoy hearty and flavorful American favorites.'
    },
    {
        name: 'Latin American',
        description: 'Immerse yourself in the bold and zesty flavors of Latin America.'
    }
];

const Menu = ({ showOverview = true }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedCuisine, setSelectedCuisine] = useState(null);

    const handleCuisineClick = (cuisine) => {
        setSelectedCuisine(cuisine);
        setModalVisible(true);
    };

    const menuOverviewContent = "Catering to anybody's flavor, Chef Jake crafts each dish with passion and creativity. Whether it's an intimate dinner or a grand celebration, the cuisines are a glimpse into the culinary adventures available. If there’s something you desire that's not on the list, let’s talk about it!";

    useEffect(() => {
        const elements = document.querySelectorAll('.menu-item');
        elements.forEach(element => {
            element.style.opacity = '0';
            element.style.transition = 'opacity 0.5s';
        });

        setTimeout(() => {
            elements.forEach(element => {
                element.style.opacity = '1';
            });
        }, 100);
    }, []);

    return (
        <section className="menu">
            <div className="menu-background"></div>
            <div className="overview">
                {showOverview && (
                    <div className="menu-overview">
                        <h2>A Note from the Chef</h2>
                        <p>{menuOverviewContent}</p>
                    </div>
                )}
            </div>
            <h2>Some cuisines to whet your appetite, anything is possible.</h2>
            <div className="menu-items">
                {cuisines.map((cuisine, index) => (
                    <div key={index} className="menu-item" onClick={() => handleCuisineClick(cuisine.name)}>
                        <h4>{cuisine.name}</h4>
                        <p>{cuisine.description}</p>
                    </div>
                ))}
            </div>
            {modalVisible && (
                <Modal
                    cuisine={selectedCuisine}
                    dishes={menuItems[selectedCuisine]}
                    onClose={() => setModalVisible(false)}
                />
            )}
        </section>
    );
};

export default Menu;
