import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import './Nav.css';

const Nav = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <header className={`header ${isScrolled ? 'scrolled' : ''}`}>
            <div className="nav-container">
                <div className="logo-container">
                    <NavLink to="/" className="logo-link" aria-label="Home">
                        <img src="/images/HCL-logo.png" className="logo" alt="Holy Cow Logo" /> {/* Corrected path */}
                    </NavLink>
                </div>
                <button className="menu-toggle" onClick={toggleMenu} aria-label="Toggle menu">
                    {menuOpen ? <FaTimes /> : <FaBars />}
                </button>
                <div className={`links-container ${isMobile ? 'mobile' : ''} ${menuOpen ? 'open' : ''}`}>
                    <nav className="nav">
                        <NavLink to="/home" activeClassName="active" className="nav-link" onClick={() => setMenuOpen(false)}>Home</NavLink>
                        <NavLink to="/story" activeClassName="active" className="nav-link" onClick={() => setMenuOpen(false)}>Chef Jake</NavLink>
                        <NavLink to="/menu" activeClassName="active" className="nav-link" onClick={() => setMenuOpen(false)}>Cuisines</NavLink>
                        <NavLink to="/gallery" activeClassName="active" className="nav-link" onClick={() => setMenuOpen(false)}>Feast With Your Eyes</NavLink>
                        <NavLink to="/testimonials" activeClassName="active" className="nav-link" onClick={() => setMenuOpen(false)}>Happy Customers</NavLink>
                        <NavLink to="/services" activeClassName="active" className="nav-link" onClick={() => setMenuOpen(false)}>Culinary Services</NavLink>
                        <NavLink to="/contact" activeClassName="active" className="nav-link" onClick={() => setMenuOpen(false)}>Let's Work Together</NavLink>
                    </nav>
                </div>
            </div>
        </header>
    );
};

export default Nav;

