import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Header, Landing, Home, Menu, Story, Gallery, Contact, Footer, Testimonials, Services, TestimonialHome, GalleryHome, StoryHome } from './components';
import MobileNav from './components/MobileNav';
import './App.css';

function App() {
    const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);

    React.useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Router>
            {isMobile ? <MobileNav /> : <Header />}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/menu" element={<Menu />} />
                <Route path="/story" element={<Story />} />
                <Route path="/story-home" element={<StoryHome />} />
                <Route path="/gallery-home" element={<GalleryHome />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/testimonials" element={<Testimonials />} />
                <Route path="/testimonial-home" element={<TestimonialHome />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/services" element={<Services />} />
            </Routes>
            <Footer />
        </Router>
    );
}

export default App;
