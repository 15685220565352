// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Apply a global font and background */


body, p {
  margin: 0;
  font-family: 'Barlow', sans-serif;
  color: white;
}

h1, h2, h3 {
  font-family: 'Italiana', serif;
}


/* Global link styles */
a {
  color: #5d4037;
  text-decoration: none;
  font-family: 'Italiana', serif;
}


body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Italiana', serif;  /* As defined earlier */
}


/* Container to hold the content */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.main-content > * {
  margin-bottom: 50px; /* Adds space between major components */
}

`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,uCAAuC;;;AAGvC;EACE,SAAS;EACT,iCAAiC;EACjC,YAAY;AACd;;AAEA;EACE,8BAA8B;AAChC;;;AAGA,uBAAuB;AACvB;EACE,cAAc;EACd,qBAAqB;EACrB,8BAA8B;AAChC;;;AAGA;EACE,yCAAyC;EACzC,wBAAwB;EACxB,8BAA8B,GAAG,uBAAuB;AAC1D;;;AAGA,kCAAkC;AAClC;EACE,iBAAiB;EACjB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,mBAAmB,EAAE,wCAAwC;AAC/D","sourcesContent":["/* Apply a global font and background */\n\n\nbody, p {\n  margin: 0;\n  font-family: 'Barlow', sans-serif;\n  color: white;\n}\n\nh1, h2, h3 {\n  font-family: 'Italiana', serif;\n}\n\n\n/* Global link styles */\na {\n  color: #5d4037;\n  text-decoration: none;\n  font-family: 'Italiana', serif;\n}\n\n\nbody {\n  background-color: var(--background-color);\n  color: var(--text-color);\n  font-family: 'Italiana', serif;  /* As defined earlier */\n}\n\n\n/* Container to hold the content */\n.container {\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 0 15px;\n}\n\n.main-content > * {\n  margin-bottom: 50px; /* Adds space between major components */\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
