import React from 'react';
import Carousel from './Carousel';
import galleryImages from './galleryImages'; // Import the gallery images data
import './Gallery.css';

const Gallery = () => {
    const travelImages = galleryImages.filter(image => image.category === 'travel');
    const foodImages = galleryImages.filter(image => image.category === 'food');

    const introText = `
        Welcome to my gallery, where culinary artistry and the spirit of adventure converge. 
        Each photo is a snapshot of my journey, capturing moments of culinary creativity and exploration from around the world. 
        From the bustling markets of Asia to the quaint cafes of Europe, and from my kitchen to yours, I invite you to experience the rich tapestry of flavors and stories that shape my cooking.
    `;

    return (
        <div className="gallery-page">
            <div className="intro">
                <div className="gallery-intro">
                    <h1>Gallery</h1>
                    <p>{introText}</p>
                </div>
            </div>
            <div className="gallery">
                <div className="gallery-section">
                    <h2>Food</h2>
                    <p>
                        Discover my culinary creations, where I blend traditional techniques with innovative ideas to craft dishes that delight the senses.
                    </p>
                    <Carousel images={foodImages} />
                </div>
                <div className="gallery-section">
                    <h2>Travel</h2>
                    <p>
                        Each journey is a new opportunity to discover and share the richness of global culinary traditions.
                    </p>
                    <Carousel images={travelImages} />
                </div>
            </div>
        </div>
    );
};

export default Gallery;

