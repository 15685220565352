import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import './MobileNav.css';

const MobileNav = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <header className="mobile-header">
            <div className="mobile-nav-container">
                <NavLink to="/" className="logo-link" aria-label="Home">
                    <img src="/images/HCL-logo.png" className="mobile-logo" alt="Holy Cow Logo" /> {/* Corrected path */}
                </NavLink>
                <button className="mobile-menu-toggle" onClick={toggleMenu} aria-label="Toggle menu">
                    {menuOpen ? <FaTimes /> : <FaBars />}
                </button>
            </div>
            <nav className={`mobile-nav ${menuOpen ? 'open' : ''}`}>
                <NavLink to="/home" className="mobile-nav-link" onClick={() => setMenuOpen(false)}>Home</NavLink>
                <NavLink to="/story" className="mobile-nav-link" onClick={() => setMenuOpen(false)}>Chef Jake</NavLink>
                <NavLink to="/menu" className="mobile-nav-link" onClick={() => setMenuOpen(false)}>Cuisines</NavLink>
                <NavLink to="/gallery" className="mobile-nav-link" onClick={() => setMenuOpen(false)}>Feast With Your Eyes</NavLink>
                <NavLink to="/testimonials" className="mobile-nav-link" onClick={() => setMenuOpen(false)}>Happy Customers</NavLink>
                <NavLink to="/services" className="mobile-nav-link" onClick={() => setMenuOpen(false)}>Culinary Services</NavLink>
                <NavLink to="/contact" className="mobile-nav-link" onClick={() => setMenuOpen(false)}>Let's Work Together</NavLink>
            </nav>
        </header>
    );
};

export default MobileNav;

