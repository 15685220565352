import React, { useState } from 'react';
import './Testimonials.css';
import testimonialData from './testimonialData';
import eventImages from './eventImages';

const Testimonials = () => {
    const [testimonialIndex, setTestimonialIndex] = useState(0);
    const [eventIndex, setEventIndex] = useState(0);

    const handleNextTestimonial = () => {
        setTestimonialIndex((prevIndex) => (prevIndex + 1) % testimonialData.length);
    };

    const handlePrevTestimonial = () => {
        setTestimonialIndex((prevIndex) => (prevIndex - 1 + testimonialData.length) % testimonialData.length);
    };

    const handleNextEvent = () => {
        setEventIndex((prevIndex) => (prevIndex + 1) % eventImages.length);
    };

    const handlePrevEvent = () => {
        setEventIndex((prevIndex) => (prevIndex - 1 + eventImages.length) % eventImages.length);
    };

    return (
        <section className="testimonials-section">
            <h2>What Our Guests Say</h2>
            <div className="testimonial-carousel">
                <button onClick={handlePrevTestimonial} className="testimonial-button prev">&laquo;</button>
                <div className="testimonial-item">
                    <blockquote>
                        “{testimonialData[testimonialIndex].quote}”
                    </blockquote>
                    <p className="author">- {testimonialData[testimonialIndex].author}</p>
                </div>
                <button onClick={handleNextTestimonial} className="testimonial-button next">&raquo;</button>
            </div>
            <h2>Previous Events</h2>
            <div className="event-carousel">
                <button onClick={handlePrevEvent} className="event-button prev">&laquo;</button>
                <div className="event-item">
                    <img
                        src={eventImages[eventIndex].src}
                        alt={`Event ${eventIndex}`}
                        className="event-image"
                        onError={(e) => { e.target.onerror = null; e.target.src='/path/to/fallback-image.jpg'; }}
                    />
                    <p className="event-description">{eventImages[eventIndex].description}</p>
                </div>
                <button onClick={handleNextEvent} className="event-button next">&raquo;</button>
            </div>
        </section>
    );
};

export default Testimonials;
