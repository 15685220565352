import React, { useState } from 'react';
import './TestimonialHome.css';
import testimonialData from './testimonialData';

const TestimonialHome = () => {
    const [index, setIndex] = useState(0);

    const handleNext = () => {
        setIndex((prevIndex) => (prevIndex + 1) % testimonialData.length);
    };

    const handlePrev = () => {
        setIndex((prevIndex) => (prevIndex - 1 + testimonialData.length) % testimonialData.length);
    };

    return (
        <section className="testimonials">
            <div className="testimonial-container">
                <div className="testimonial-item">
                    <blockquote>
                        “{testimonialData[index].quote}”
                    </blockquote>
                    <p className="author">- {testimonialData[index].author}</p>
                </div>
            </div>
            <div className="testimonial-buttons">
                <button onClick={handlePrev} className="testimonial-button prev">&laquo;</button>
                <button onClick={handleNext} className="testimonial-button next">&raquo;</button>
            </div>
        </section>
    );
};

export default TestimonialHome;
