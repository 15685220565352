// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  min-height: 100vh; /* Make sure the App fills the full height */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* This will push the footer to the bottom */
}

:root {
  --primary-color: #8B0000;
  --primary-scrolled-color: #e02627;
  --secondary-color: #8B4513;
  --accent-color: #F5F5DC;
  --background-color: #FFF8DC;
  --text-color: #3E2723;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-content {
  padding-top: 100px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh; /* Use the full height of the viewport */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB,EAAE,4CAA4C;EAC/D,aAAa;EACb,sBAAsB;EACtB,8BAA8B,EAAE,4CAA4C;AAC9E;;AAEA;EACE,wBAAwB;EACxB,iCAAiC;EACjC,0BAA0B;EAC1B,uBAAuB;EACvB,2BAA2B;EAC3B,qBAAqB;AACvB;;;AAGA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,yBAAyB;EACzB,iBAAiB,EAAE,wCAAwC;EAC3D,aAAa;EACb,sBAAsB;EACtB,mBAAmB,EAAE,wBAAwB;EAC7C,uBAAuB,EAAE,sBAAsB;EAC/C,6BAA6B;EAC7B,YAAY;AACd;;;AAGA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".App {\n  min-height: 100vh; /* Make sure the App fills the full height */\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between; /* This will push the footer to the bottom */\n}\n\n:root {\n  --primary-color: #8B0000;\n  --primary-scrolled-color: #e02627;\n  --secondary-color: #8B4513;\n  --accent-color: #F5F5DC;\n  --background-color: #FFF8DC;\n  --text-color: #3E2723;\n}\n\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n.App-content {\n  padding-top: 100px;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh; /* Use the full height of the viewport */\n  display: flex;\n  flex-direction: column;\n  align-items: center; /* Center horizontally */\n  justify-content: center; /* Center vertically */\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
