import React from 'react';
import './Home.css';
import { Landing, StoryHome, Menu, Contact, GalleryHome, TestimonialHome } from '.';

const Home = () => (
  <div className="home">
    <Landing />
    <div id="story"><StoryHome /></div>
    <div id="menu"><Menu /></div>
    <div id="gallery"><GalleryHome /></div>
    <div id="testimonials"><TestimonialHome /></div>
    <div id="contact"><Contact /></div>
  </div>
);

export default Home;
