const eventImages = [
    {
        src: '/images/dinnerparty.webp',
        description: 'Chef Jake and Chef Suzy preparing to cook their meal for a dinner party in Island Estates, Clearwater, FL'
    },
    {
        src: '/images/dinnerparty2.webp',
        description: 'Short rib ragu with homemade fettucine for a party of 12. A beautiful meal for a night conversating with close friends!'
    },
    {
        src: '/images/dinnerparty3.webp',
        description: 'Gorgonzola gnocchi accompanied by eggplant parmesean while Chef Jake was staying in the Tuscan Hillside with classmates.'
    }
];

export default eventImages;
