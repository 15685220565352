import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './StoryHome.css';
import StoryModal from './StoryModal';

const StoryHome = ({ showOverview = true }) => {
    const [activeModal, setActiveModal] = useState(null);
    const navigate = useNavigate();

    const closeModal = () => {
        setActiveModal(null);
    };

    const overviewContent = `
    Jake Yannes, a passionate chef with a deep love for culinary arts, brings together flavors from around the world to create unforgettable dining experiences. 
    Join him as he explores the fusion of traditional and modern cuisines in his quest to nourish and delight.
    `;

    const content = {
        journey: `
        - From a young age, my fascination with food has shaped my life's path.
        - My culinary adventures were precocious and adventurous, with a palate always eager for new experiences.
        - During COVID-19, my passion for cooking evolved into crafting extensive feasts for family gatherings.
        - While pursuing a degree in data analytics, I worked at Suraya, a renowned Lebanese restaurant in Philadelphia.
        - The experience at Philadelphia Eagles tailgates honed my skills at large-scale meal preparation.
        - Working at a donation-based homeless shelter kitchen taught me the true essence of nourishment and compassionate cooking.
        - Food is more than sustenance; it is a medium for connection, an expression of culture, and a catalyst for community.
        `,
        holyCow: `
        - "Holy Cow Lightning!" is a vibrant slice of my family's history and the spirit behind my culinary venture.
        - My family owned a butcher shop in Hazleton, Pennsylvania, during the Great Depression.
        - They pioneered the concept of a refrigerated truck, laying the groundwork for modern food distribution methods.
        - A visit to Castelfondo in the Dolomites connected me to my family's past.
        - Cherished memories of my grandfather teaching me the art of slicing salami.
        - "Holy Cow Lightning" is a continuation of my family’s legacy and a celebration of our history.
        `,
        inspiration: `
        - My culinary philosophy is profoundly shaped by Chef Anthony Bourdain.
        - Bourdain's belief that "Food is everything we are" guides my approach to cooking.
        - His series, Parts Unknown, inspired me to explore Europe and Asia, forging connections through food.
        - In the kitchen, I am influenced by Chef J. Kenji López-Alt's blend of culinary art with scientific principles.
        - López-Alt’s approach to understanding the chemistry of cooking encourages experimentation and innovation.
        - I see food as a bridge between cultures and science as a tool to enhance the art of gastronomy.
        `,
        philosophy: `
        - Deeply rooted in the belief that "food is love" and that cooking is an act of nourishment.
        - Commitment to source the finest ingredients possible, understanding their origins and ensuring they reflect the principles of quality and respect for the environment.
        - Meals are the fundamental part of any gathering, celebration, or simple daily comfort & food has the unique capacity to bring people together, fostering connections and creating moments to cherish.
        - In every dish I prepare, I aim to honor these moments, crafting meals that not only satiate the appetite but also nourish the soul and express gratitude for life's most significant bonds—those we share with our loved ones.
        `
    };

    const getTitle = (key) => {
        switch (key) {
            case 'journey':
                return 'My Culinary Journey';
            case 'holyCow':
                return 'What is Holy Cow Lightning?';
            case 'inspiration':
                return 'My Inspiration';
            case 'philosophy':
                return 'Culinary Philosophy';
            default:
                return '';
        }
    };

    return (
        <div className='storyOverview'>
            <section className='overview' style={{ backgroundImage: `url(/images/kitchenBackground.jpg)`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <div className="story-overview">
                    <img src="/images/chefImage.webp" alt="Chef Jake Yannes" />
                    <div className="story-overview-content">
                        <h1>About Chef Jake</h1>
                        <p>{overviewContent}</p>
                        <div className="decorative-divider"></div>
                        <p className="quote">"Cooking is an expression of the land where you are and the culture of that place." - Wolfgang Puck</p>
                    </div>
                </div>
            </section>

            <section className="story">
                <div className="story-row">
                    <div className="story-card" onClick={() => setActiveModal('journey')}>
                        <h2>My Culinary Journey</h2>
                        <img src="/images/eagles.webp" alt="My Culinary Journey" />
                    </div>
                    <div className="story-card" onClick={() => setActiveModal('holyCow')}>
                        <h2>What is Holy Cow Lightning?</h2>
                        <img src="/images/yannes.webp" alt="Holy Cow Lightning Concept" />
                    </div>
                    <div className="story-card" onClick={() => setActiveModal('inspiration')}>
                        <h2>My Inspiration</h2>
                        <img src="/images/bourdain-1.webp" alt="My Inspiration" />
                    </div>
                    <div className="story-card" onClick={() => setActiveModal('philosophy')}>
                        <h2>Culinary Philosophy</h2>
                        <img src="/images/market.webp" alt="Culinary Philosophy" />
                    </div>
                </div>
                <StoryModal isOpen={activeModal !== null} onClose={closeModal} title={getTitle(activeModal)} content={content[activeModal]} />
            </section>
        </div>
    );

};

export default StoryHome;

