import React from 'react';
import Carousel from './Carousel';
import galleryImages from './galleryImages'; // Import the gallery images data
import './Gallery.css';

const GalleryHome = () => {
    const homeImages = galleryImages.slice(0, 6);

    return (
        <div className="gallery">
            <Carousel images={homeImages} />
        </div>
    );
};

export default GalleryHome;
