// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.modal.open {
    opacity: 1;
    visibility: visible;
}

.modalContent {
    background-color: #fefefe;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 500px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    font-family: 'Roboto', sans-serif;
    position: relative;
    transform: translateY(-50px);
    transition: transform 0.3s ease;
}

.modal.open .modalContent {
    transform: translateY(0);
}

.closeButton {
    color: #aaa;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.closeButton:hover {
    color: #000;
}
`, "",{"version":3,"sources":["webpack://./src/components/ContactModal.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,aAAa;IACb,OAAO;IACP,MAAM;IACN,WAAW;IACX,YAAY;IACZ,cAAc;IACd,oCAAoC;IACpC,UAAU;IACV,kBAAkB;IAClB,mDAAmD;AACvD;;AAEA;IACI,UAAU;IACV,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,UAAU;IACV,gBAAgB;IAChB,qCAAqC;IACrC,iCAAiC;IACjC,kBAAkB;IAClB,4BAA4B;IAC5B,+BAA+B;AACnC;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".modal {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    position: fixed;\n    z-index: 1000;\n    left: 0;\n    top: 0;\n    width: 100%;\n    height: 100%;\n    overflow: auto;\n    background-color: rgba(0, 0, 0, 0.5);\n    opacity: 0;\n    visibility: hidden;\n    transition: opacity 0.3s ease, visibility 0.3s ease;\n}\n\n.modal.open {\n    opacity: 1;\n    visibility: visible;\n}\n\n.modalContent {\n    background-color: #fefefe;\n    padding: 20px;\n    border-radius: 10px;\n    width: 80%;\n    max-width: 500px;\n    box-shadow: 0 4px 8px rgba(0,0,0,0.1);\n    font-family: 'Roboto', sans-serif;\n    position: relative;\n    transform: translateY(-50px);\n    transition: transform 0.3s ease;\n}\n\n.modal.open .modalContent {\n    transform: translateY(0);\n}\n\n.closeButton {\n    color: #aaa;\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    font-size: 28px;\n    font-weight: bold;\n    cursor: pointer;\n}\n\n.closeButton:hover {\n    color: #000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
