// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  background-color: var(--primary-scrolled-color);
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  bottom: 0;
}

.footer-column {
  flex: 1 1;
  text-align: center;
}

.footer-logo {
  max-width: 150px;
  width: 100%;
}

.footer-column p {
  margin: 0.5rem 0;
  font-size: 1rem;
}

.footer-column a {
  color: #fff;
  text-decoration: none;
  font-family: Barlow;
}

.footer-column a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    text-align: center;
  }

  .footer-column {
    margin-bottom: 1rem;
  }

  .footer-logo {
    margin-bottom: 1rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA;EACE,+CAA+C;EAC/C,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,SAAO;EACP,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE;IACE,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".footer {\n  background-color: var(--primary-scrolled-color);\n  color: #fff;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 1rem 2rem;\n  bottom: 0;\n}\n\n.footer-column {\n  flex: 1;\n  text-align: center;\n}\n\n.footer-logo {\n  max-width: 150px;\n  width: 100%;\n}\n\n.footer-column p {\n  margin: 0.5rem 0;\n  font-size: 1rem;\n}\n\n.footer-column a {\n  color: #fff;\n  text-decoration: none;\n  font-family: Barlow;\n}\n\n.footer-column a:hover {\n  text-decoration: underline;\n}\n\n@media (max-width: 768px) {\n  .footer {\n    flex-direction: column;\n    text-align: center;\n  }\n\n  .footer-column {\n    margin-bottom: 1rem;\n  }\n\n  .footer-logo {\n    margin-bottom: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
