import React from 'react';
import './Footer.css';


const Footer = () => (
  <footer className="footer">
    <div className="footer-column">
      <p>Site designed, coded, and maintained by Chef Jake</p>
      <p>&copy; {new Date().getFullYear()} Holy Cow Lightning Culinary. All rights reserved.</p>
    </div>
    <div className="footer-column">
      <p>Chef Jake Yannes | Tampa Bay Private Chef</p>
      <p>415 Island Way</p>
      <p>Clearwater, FL 33767</p>
      <p>(484) 788-9369</p>
      <p><a href="mailto:CHEFJAKE@HOLYCOWLIGHTNING.COM">chefjake@holycowlightning.com</a></p>
    </div>
  </footer>
);

export default Footer;
