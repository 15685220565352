const galleryImages = [
    { src: '/images/tomyum.webp', alt: 'Tom Yum Soup', category: 'food' },
    { src: '/images/samke.webp', alt: 'Samke Harrah', category: 'food' },
    { src: '/images/vodka.webp', alt: 'Pasta with Vodka Sauce', category: 'food' },
    { src: '/images/pasta3.webp', alt: 'Handmade Pasta', category: 'food' },
    { src: '/images/duck.webp', alt: 'Duck Confit', category: 'food' },
    { src: '/images/biscuits.webp', alt: 'Sausage Gravy and Biscuits', category: 'food' },
    { src: '/images/carb.webp', alt: 'Carbonara', category: 'food' },
    { src: '/images/char-oysters.webp', alt: 'Charbroiled Oysters', category: 'food' },
    { src: '/images/fennel.webp', alt: 'Grilled fennel', category: 'food' },
    { src: '/images/flo.webp', alt: 'Chicken Piccata', category: 'food' },
    { src: '/images/gnocchi.webp', alt: 'Gnocchi with Guanciale and Fennel', category: 'food' },
    { src: '/images/gnocchisardi.webp', alt: 'Homemade Gnocchi Sardi', category: 'food' },
    { src: '/images/gnocchivodka.webp', alt: 'Gnocchi Sardi with Vodka Sauce', category: 'food' },
    { src: '/images/hep.webp', alt: 'Volunteers while I am cooking at HEP', category: 'food' },
    { src: '/images/lamb-shoulder.webp', alt: 'Roasted Lamb Shoulder, rotisserie style', category: 'food' },
    { src: '/images/mongolianbeef.webp', alt: 'Slow cooker Mongolian beef noodles', category: 'food' },
    { src: '/images/monkfish.webp', alt: 'Monkfish Puttanesca with Gnocchi Sardi', category: 'food' },
    { src: '/images/oyster.webp', alt: 'Oyster with Yuzu mignonette and Asian cucumber salad', category: 'food' },
    { src: '/images/porkchops2.webp', alt: 'Grilled porkchops with pickled jalapenos and Roasted potatoes', category: 'food' },
    { src: '/images/porktenderloin.webp', alt: 'Marinated pork tenderloin over Cous Cous and Roasted Potatoes', category: 'food' },
    { src: '/images/ramen.webp', alt: 'Homemade ramen dashi with gooey egg', category: 'food' },
    { src: '/images/ribeye.webp', alt: 'Ribeye with tomato salad and sauteed spinach', category: 'food' },
    { src: '/images/roast.webp', alt: 'Rotisserie Chicken', category: 'food' },
    { src: '/images/thaicurry.webp', alt: "People's Choice Thai-style Chili", category: 'food' },
    { src: '/images/tunasteak.webp', alt: 'Tuna Steak with Asian cucumber salad', category: 'food' },
    
    { src: '/images/travel1.webp', alt: "Bistrot 64 -- Featured on Stanley Tucci's 'Searching for Italy'", category: 'travel' },
    { src: '/images/travel2.webp', alt: 'Tom Yum Soup -- Bangkok, Thailand', category: 'travel' },
    { src: '/images/travel3.webp', alt: 'Classic Beef Noodle Soup -- Rong Klan Nuea Noodle Shop, Bangkok, Thailand', category: 'travel' },
    { src: '/images/travel4.webp', alt: 'Dinner of Duck Confit roasted over a bed of green beans -- Roger Brun Champagne, Ay-Champagne, France', category: 'travel' },
    { src: '/images/travel5.webp', alt: 'Touring Prosciutto Production Facility -- Parma, Italy', category: 'travel' },
    { src: '/images/travel6.webp', alt: 'Lunch at Ponto Final -- Almada, Portugal', category: 'travel' },
    { src: '/images/travel7.webp', alt: 'Dinner at Elephant Sanctuary -- Mae Taeng, Thailand', category: 'travel' },
    { src: '/images/travel8.webp', alt: 'Jamon Iberico stand -- Mercado Boqueria, Barcalona, Spain', category: 'travel' },
    { src: '/images/travel9.webp', alt: 'Thai omelette cooked in banana leaf -- Mae Taeng, Thailand', category: 'travel' },
    { src: '/images/travel10.webp', alt: 'Jamon Iberico -- Mercado Boqueria, Barcalona, Spain', category: 'travel' },
    { src: '/images/travel11.webp', alt: 'Jamon Iberico -- Beher, Lisbon, Portugal', category: 'travel' },
    { src: '/images/travel12.webp', alt: 'Rong Klan Nuea Noodle Shop, Bangkok Thailand', category: 'travel' },
    { src: '/images/travel13.webp', alt: 'Polish Kielbasa with cabbage and potatoes -- Wieliczka, Poland', category: 'travel' },
    { src: '/images/travel14.webp', alt: 'Pizza with Porcini and Prosciutto -- Prati, Roma, Italy', category: 'travel' },
    { src: '/images/travel15.webp', alt: 'Beef Boat Noodles -- Bangkok, Thailand', category: 'travel' },
    { src: '/images/travel16.webp', alt: 'Haggis with a Guinness -- Edinburgh, Scotland', category: 'travel' },
    { src: '/images/travel17.webp', alt: 'Cacio e Pepe -- 3 Quarti, Roma, Italy', category: 'travel' },
    { src: '/images/travel18.webp', alt: 'Agnolotti in broth -- Ristorante Cocchi, Parma, Italy', category: 'travel' },
    { src: '/images/travel19.webp', alt: 'Pappardelle with Wild Boar Ragu -- Titignagno, Umbria, Italy', category: 'travel' },
    { src: '/images/travel20.webp', alt: 'Pork belly with chili -- Bangkok, Thailand', category: 'travel' },
    { src: '/images/travel21.webp', alt: 'Carbonara -- My Relatives in Val Di Non, Italy', category: 'travel' },
    { src: '/images/travel22.webp', alt: "Frutti Di Mare -- Gerry's Pub, Amalfi, Italy", category: 'travel' },
    { src: '/images/travel23.webp', alt: 'Octopus -- Lisbon, Portugal', category: 'travel' },
    { src: '/images/travel24.webp', alt: 'Duck with Demi Glace -- Lisbon, Portugal', category: 'travel' },
    { src: '/images/travel25.webp', alt: "Butcher Shop in my Ancestor's village -- Castelfondo, Italy ", category: 'travel' },
    { src: '/images/travel26.webp', alt: 'Poached Bonito -- Corniglia, Italy', category: 'travel' },
    { src: '/images/travel27.webp', alt: 'Bruschetta Appetizers -- Manarola, Cinque Terre, Italy', category: 'travel' },
    { src: '/images/travel28.webp', alt: 'Frito Mixto -- Venice, Italy', category: 'travel' },
    { src: '/images/travel29.webp', alt: 'Parmasean Farm -- Parma, Italy', category: 'travel' },
    { src: '/images/travel30.webp', alt: 'Tyrolean Gray Cows -- Grimzens, Austria', category: 'travel' },
    { src: '/images/travel31.webp', alt: 'Khao Soi and Northern Thai Sausage -- Phaya Thai, Bangkok, Thailand', category: 'travel' },
    { src: '/images/travel32.webp', alt: 'Papaya Salad -- Phaya Thai, Bangkok, Thailand', category: 'travel' },
    { src: '/images/travel33.webp', alt: 'Asparagus Risotto -- Bolzano, Italy', category: 'travel' },
    { src: '/images/travel34.webp', alt: 'Mango Sticky Rice -- Mae Taeng, Thailand', category: 'travel' }
];

export default galleryImages;
