import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Services.css';

const servicesDetails = [
    { 
        title: 'Meal Prep Service', 
        description: 'Custom meal prep services tailored to your dietary preferences and nutritional needs. Enjoy healthy, delicious meals without the hassle of cooking.', 
        details: 'We offer personalized meal prep services, taking into account your dietary restrictions, preferences, and nutritional goals. Our meal prep service includes weekly menus, grocery shopping, cooking, and packaging. We ensure that you always have nutritious and tasty meals ready to enjoy.',
        events: ['Weekly Meal Prep', 'Special Diet Plans', 'Family Meals'] 
    },
    { 
        title: 'Catering Small Events', 
        description: 'Personalized catering services for intimate gatherings and special occasions. Make your event memorable with gourmet dishes.', 
        details: 'Our catering service is perfect for small events, such as birthday parties, anniversaries, and corporate meetings. We work closely with you to design a menu that reflects your tastes and the theme of your event. From appetizers to desserts, we handle everything, so you can focus on enjoying the occasion.',
        events: ['Birthday Parties', 'Anniversaries', 'Corporate Meetings', 'Private Dinners', 'Bachelor/Bachelorette Parties', 'Boating Days'] 
    },
    { 
        title: 'Cooking Classes', 
        description: 'Interactive cooking classes focusing on a variety of cuisines and techniques. Learn, cook, and enjoy delicious meals.', 
        details: 'Join our interactive cooking classes and learn how to create culinary masterpieces. Our classes cover a wide range of cuisines and techniques, from basic knife skills to advanced cooking methods. Whether you are a beginner or an experienced cook, our classes are designed to be fun and educational.',
        events: ['Beginner Classes', 'Advanced Techniques', 'Team Building Events'] 
    }
];

const Services = () => {
    const navigate = useNavigate();

    return (
        <div className="services-page">
            <div className="content-wrapper">
                <h1> Culinary Services</h1>
                <p className="intro-text">
                    Holy Cow Lightning brings gourmet experiences directly to you. Led by Chef Jake Yannes, our services are crafted to provide you with unforgettable culinary experiences, tailored to your needs. Explore our range of services below and discover how we can make your next event extraordinary.
                </p>
                <div className="credentials">
                    
                    <p>
                        With a background in both fine dining and community service, Chef Jake Yannes combines culinary artistry with a passion for bringing people together through food. From preparing meals for over 50 individuals daily at a community shelter to serving intricate dishes at high-end restaurants, Chef Jake's journey reflects his commitment to excellence and compassion. Certified by ServSafe and experienced in various culinary settings, Chef Jake ensures that every meal is not only delicious but also prepared with the highest standards of safety and hygiene.
                    </p>
                    
                </div>
                <div className="services-list">
                    {servicesDetails.map((service, index) => (
                        <div key={index} className="service-detail-card">
                            <h2>{service.title}</h2>
                            <p>{service.description}</p>
                            <p>{service.details}</p>
                            <ul>
                                {service.events.map((event, idx) => (
                                    <li key={idx}>{event}</li>
                                ))}
                            </ul>
                            <div className="cta-button-wrapper">
                                <button className="cta-button" onClick={() => navigate('/contact')}>Contact for More Info</button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Services;
