import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './Landing.css';

function Landing() {
    const videoRef = useRef(null);
    const navigate = useNavigate();
    const isMobile = window.innerWidth <= 768;

    useEffect(() => {
        const video = videoRef.current;
        if (video) {
            video.playbackRate = 0.5; // Slows down the video to half its original speed
            video.setAttribute('playsinline', 'true'); // Necessary for iOS devices to autoplay
            video.setAttribute('muted', 'true'); // Ensure the video is muted
            video.setAttribute('autoplay', 'true'); // Ensure the video is set to autoplay
            video.setAttribute('loop', 'true'); // Ensure the video is set to loop

            const playPromise = video.play();
            if (playPromise !== undefined) {
                playPromise.then(() => {
                    // Automatic playback started!
                }).catch(error => {
                    // Auto-play was prevented
                    console.log('Autoplay prevented:', error);
                });
            }
        }
    }, []);

    const handleNavigation = (mobileId, desktopPath) => {
        if (isMobile) {
            const element = document.getElementById(mobileId);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            navigate(desktopPath);
        }
    };

    return (
        <section className="landing" role="banner">
            <div className="video-wrapper">
                <video autoPlay loop muted ref={videoRef} className="hero-video" aria-label="Background video of culinary preparations" playsInline>
                    <source src="/videos/landing.mp4" type="video/mp4" />
                </video>
            </div>
            <div className="overlay"></div>
            <div className="text-content">
                <h1 className="fade-in">Nourish The People.</h1>
                <p className="fade-in">
                    Experience the luxury of personalized dining with Chef Jake Yannes in the Tampa Bay area and beyond. From intimate dinners to grand celebrations, discover diverse flavors and unforgettable moments crafted from the finest, locally-sourced ingredients.
                </p>
                <div className="cta-buttons fade-in">
                    <button className="cta-button" onClick={() => handleNavigation('story', '/story')}>Meet the Chef</button>
                    <button className="cta-button" onClick={() => handleNavigation('menu', '/menu')}>Explore Cuisines</button>
                    <button className="cta-button" onClick={() => handleNavigation('contact', '/contact')}>Let's Talk</button>
                </div>
                <div className="social-links fade-in">
                    <a href="https://www.instagram.com/holycowlightning/" aria-label="Instagram" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-instagram"></i>
                    </a>
                    <a href="mailto:CHEFJAKE@HOLYCOWLIGHTNING.COM" aria-label="Instagram" target="_blank" rel="noopener noreferrer">
                        <i class="fa-solid fa-envelope"></i>
                    </a>
                </div>
            </div>
        </section>
    );
}

export default Landing;
