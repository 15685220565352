import React, { useEffect, useState } from 'react';
import './ContactModal.css';

const ContactModal = ({ message, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(onClose, 300);
  };

  return (
    <div className={`modal ${isOpen ? 'open' : ''}`}>
      <div className="modalContent">
        <span className="closeButton" onClick={handleClose}>&times;</span>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default ContactModal;
