import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Contact.css';
import ContactModal from './ContactModal';
import { useNavigate } from 'react-router-dom';

const services = [
    { title: 'Meal Prep Service', description: 'Custom meal prep services tailored to your dietary preferences and nutritional needs.' },
    { title: 'Catering Small Events', description: 'Personalized catering services for intimate gatherings and special occasions.' },
    { title: 'Cooking Classes', description: 'Interactive cooking classes focusing on a variety of cuisines and techniques.' },
];

function Contact() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        occasion: '',
        guests: '',
        date: '',
        location: '',
        startTime: '',
        endTime: '',
        message: ''
    });

    const [errors, setErrors] = useState({});
    const [modalVisible, setModalVisible] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const navigate = useNavigate();

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const validate = () => {
        let tempErrors = {};
        const today = new Date().toISOString().split('T')[0];
        tempErrors.firstName = formData.firstName ? "" : "First Name is required.";
        tempErrors.lastName = formData.lastName ? "" : "Last Name is required.";
        tempErrors.phone = formData.phone && /^[0-9\b]+$/.test(formData.phone) ? "" : "Valid Phone Number is required.";
        tempErrors.email = formData.email && /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(formData.email) ? "" : "Valid Email is required.";
        tempErrors.guests = formData.guests && !isNaN(formData.guests) ? "" : "Number of Guests is required and must be a number.";
        tempErrors.date = formData.date && formData.date >= today ? "" : "Event Date is required and must be in the future.";
        setErrors(tempErrors);

        return Object.values(tempErrors).every(x => x === "");
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (validate()) {
            emailjs.send('service_zq0qn3f', 'template_ycw9gwb', formData, 'wQ32LH09UG5koANdO')
                .then((result) => {
                    console.log(result.text);
                    setModalMessage('Thank you for your inquiry. We will get back to you shortly!');
                    setModalVisible(true);
                    setFormData({
                        firstName: '',
                        lastName: '',
                        phone: '',
                        email: '',
                        occasion: '',
                        guests: '',
                        date: '',
                        location: '',
                        startTime: '',
                        endTime: '',
                        message: ''
                    });
                }, (error) => {
                    console.log(error.text);
                    setModalMessage('Something went wrong, please try again later.');
                    setModalVisible(true);
                });
        } else {
            setModalMessage('Please fill out all required fields correctly.');
            setModalVisible(true);
        }
    };

    const closeModal = () => {
        setModalVisible(false);
    };

    const handleServiceClick = () => {
        navigate('/services');
    };

    return (
        <div className="contact-page">
            <div className="services-section">
                <h2>Our Services</h2>
                <p className="services-description">
                    At Holy Cow Lightning Culinary, we offer a range of bespoke culinary services designed to meet your needs. Whether you need custom meal preparation, catering for intimate events, or interactive cooking classes, we provide personalized and memorable culinary experiences. Contact us to get a quote and let us make your culinary dreams come true.
                </p>
                <div className="services-grid">
                    {services.map((service, index) => (
                        <div key={index} className="service-card" onClick={handleServiceClick}>
                            <h3>{service.title}</h3>
                            <p>{service.description}</p>
                            <button className="cta-button">Learn More</button>
                        </div>
                    ))}
                </div>
            </div>
            <div className="contact-container" id="contact-form">
                <h2>Reserve Your Culinary Experience</h2>
                <form onSubmit={handleSubmit} className="contact-form">
                    <label htmlFor="firstName">First Name</label>
                    <input id="firstName" name="firstName" value={formData.firstName} onChange={handleChange} type="text" placeholder="First Name" required />
                    {errors.firstName && <p className="error">{errors.firstName}</p>}

                    <label htmlFor="lastName">Last Name</label>
                    <input id="lastName" name="lastName" value={formData.lastName} onChange={handleChange} type="text" placeholder="Last Name" required />
                    {errors.lastName && <p className="error">{errors.lastName}</p>}

                    <label htmlFor="phone">Phone</label>
                    <input id="phone" name="phone" value={formData.phone} onChange={handleChange} type="text" placeholder="Phone" />
                    {errors.phone && <p className="error">{errors.phone}</p>}

                    <label htmlFor="email">Email</label>
                    <input id="email" name="email" value={formData.email} onChange={handleChange} type="email" placeholder="your.email@example.com" required />
                    {errors.email && <p className="error">{errors.email}</p>}

                    <label htmlFor="occasion">What is the occasion of the event?</label>
                    <input id="occasion" name="occasion" value={formData.occasion} onChange={handleChange} type="text" placeholder="Occasion" />

                    <label htmlFor="guests">How many attending event?</label>
                    <input id="guests" name="guests" value={formData.guests} onChange={handleChange} type="number" placeholder="Number of Guests" required />
                    {errors.guests && <p className="error">{errors.guests}</p>}

                    <label htmlFor="date">Event Date</label>
                    <input id="date" name="date" value={formData.date} onChange={handleChange} type="date" required />
                    {errors.date && <p className="error">{errors.date}</p>}

                    <label htmlFor="location">Location of event</label>
                    <input id="location" name="location" value={formData.location} onChange={handleChange} type="text" placeholder="Event Location" />

                    <label htmlFor="startTime">Event Start Time</label>
                    <input id="startTime" name="startTime" value={formData.startTime} onChange={handleChange} type="time" />

                    <label htmlFor="endTime">Event End Time</label>
                    <input id="endTime" name="endTime" value={formData.endTime} onChange={handleChange} type="time" />

                    <label htmlFor="message">Additional information</label>
                    <textarea id="message" name="message" value={formData.message} onChange={handleChange} placeholder="Any special requests?"></textarea>

                    <button type="submit">Submit</button>
                </form>
            </div>
            {modalVisible && <ContactModal message={modalMessage} onClose={closeModal} />}
        </div>
    );
}

export default Contact;
