export { default as Header } from './Nav';
export { default as Landing } from './Landing';
export { default as Home } from './Home';
export { default as Menu } from './Menu';
export { default as Services } from './Services';
export { default as Story } from './Story';
export { default as StoryHome } from './StoryHome';
export { default as GalleryHome } from './GalleryHome';
export { default as Gallery } from './Gallery';
export { default as Testimonials } from './Testimonials';
export { default as TestimonialHome } from './TestimonialHome';
export { default as Contact } from './Contact';
export { default as Footer } from './Footer';
