const testimonialData = [
    {
        quote: "Jake prepared an unforgettable dinner for us that showcased his culinary skills and passion for cooking. The meal began with an exquisite ahi tuna appetizer, which was fresh and perfectly seasoned, setting the tone for the evening. The main course featured grilled lamb chops, cooked to perfection, accompanied by a delightful vegetable medley that was both fresh and flavorful. Each dish was thoughtfully crafted, and the entire dining experience was incredible. Jake's ability to create such a memorable meal truly impressed us, and I highly recommend his cooking to anyone looking for an exceptional dining experience.",
        author: "Kurt K.",
        image: '/path/to/image1.jpg'
    },
    {
        quote: "Absolutely fantastic service and quality. Chef Jake made our anniversary dinner unforgettable.",
        author: "Michael B.",
        image: '/path/to/image2.jpg'
    },
    {
        quote: "The culinary workshop was enlightening! I learned so much and had a great time.",
        author: "Julia R.",
        image: '/path/to/image3.jpg'
    }
];

export default testimonialData;
