import React, { useState } from 'react';
import './Carousel.css';

const Carousel = ({ images }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const nextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    const openModal = (index) => {
        setCurrentImageIndex(index);
        document.getElementById("myModal").style.display = "block";
    };

    const closeModal = () => {
        document.getElementById("myModal").style.display = "none";
    };

    return (
        <div className="carousel-container">
            <button className="carousel-button prev" onClick={prevImage}>&#8249;</button>
            <div className="carousel-content" onClick={() => openModal(currentImageIndex)}>
                <img src={images[currentImageIndex].src} alt={images[currentImageIndex].alt} />
                <div className="caption">{images[currentImageIndex].alt}</div>
            </div>
            <button className="carousel-button next" onClick={nextImage}>&#8250;</button>
            <div className="carousel-dots">
                {images.map((_, index) => (
                    <span
                        key={index}
                        className={`dot ${currentImageIndex === index ? 'active' : ''}`}
                        onClick={() => setCurrentImageIndex(index)}
                    ></span>
                ))}
            </div>
            <div id="myModal" className="modal">
                <span className="close" onClick={closeModal}>&times;</span>
                <div className="modal-content">
                    <img src={images[currentImageIndex].src} alt={images[currentImageIndex].alt} />
                </div>
            </div>
        </div>
    );
};

export default Carousel;
