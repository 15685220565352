import React from 'react';
import './Story.css';

const Story = () => {
    const content = {
        journey: {
            text: `From a young age, my fascination with food has shaped my life's path in remarkable ways. 
            As a child, my culinary adventures were precocious and adventurous—from savoring surf clam and octopus at local sushi spots to choosing rack of lamb at age nine, my palate was always eager for new experiences.
            
            This early enthusiasm evolved into a passionate engagement with cooking during the unexpected global pause brought on by COVID-19. 
            What started as preparing daily meals for my family blossomed into crafting extensive feasts for over twenty relatives at gatherings, turning these events into celebrations of shared flavors and stories.
            
            In my senior year of college, while pursuing a degree in data analytics, I found a new passion—working in the high-energy environment of a fine dining kitchen. 
            This led me to Suraya, a renowned Lebanese restaurant in Philadelphia. 
            Juggling a full academic schedule with ten-hour work shifts, I thrived in the bustling kitchen atmosphere, absorbing every technique and trick shared by my mentors. 
            The fast-paced world of preparing for over 400 guests per night was both exhilarating and formative.
            
            Another chapter of my culinary story unfolded in the spirited ambiance of Philadelphia Eagles tailgates—a true test of my adaptability and creativity. 
            There, I served everything from sausage biscuits and gravy at dawn to charbroiled oysters and handmade carbonara pasta, feeding crowds of enthusiastic fans. 
            This experience not only honed my skills at large-scale meal preparation but also deepened my appreciation for the community-building power of food.
            
            My culinary journey took an even more meaningful turn when I began working at a donation-based homeless shelter kitchen. 
            This role challenged me to deliver high-quality, nutritious meals, regardless of the resources available. 
            Each dish I prepare is crafted with care and respect, ensuring that every meal is a dignified, enjoyable experience for those we serve. 
            This position has been profoundly rewarding, teaching me the true essence of nourishment and the impact of thoughtful, compassionate cooking.
            
            Through each of these experiences, I've learned that food is more than sustenance—it's a medium for connection, an expression of culture, and a catalyst for community. 
            As I continue to grow as a chef and as a person, I am committed to bringing people together around the table, one meal at a time.`,
            image: '/images/eagles.webp',
            caption: "Grilling at Philadelphia Eagles tailgate."
        },
        holyCow: {
            text: `"Holy Cow Lightning!" is not just a quirky expression from my grandfather—it's a vibrant slice of my family's history and the spirit behind my culinary venture. 
            This unique phrase, which he used in every conceivable situation, captures the essence of surprise and innovation that characterized my family's approach to life and business.
            
            During the challenging times of the Great Depression, my family owned a butcher shop in Hazleton, Pennsylvania. 
            It was here that they pioneered the concept of a refrigerated truck, an ingenious solution that allowed them to deliver fresh meats directly to customers’ doorsteps. 
            This innovation not only helped sustain the business through tough economic times but also laid the groundwork for modern food distribution methods.
            
            My connection to my family's past deepened during a visit to Castelfondo in the Dolomites, the village in Italy from where my ancestors immigrated. 
            Standing in a local butcher shop, I discovered that the butcher's grandmother shared the Yannes surname—a serendipitous link that underscored the enduring ties between my family's legacy and their homeland.
            
            The most cherished memories of my grandfather involve the times he would take me to the basement where they kept the original meat slicer from the shop. 
            There, he taught me the art of slicing salami, carefully instructing me on the importance of thickness and technique. 
            Each slice was a lesson in patience and precision, wrapped up in the rich, savory aromas of freshly cut salami.
            
            Through "Holy Cow Lightning," I aim to honor these traditions and memories. 
            It represents more than just a culinary brand; it is a continuation of my family’s legacy, a celebration of our history, and an invitation to others to experience the rich tapestry of flavors and stories that shape my cooking today.`,
            image: '/images/yannes.webp',
            caption: "Family butcher shop in Hazleton, Pennsylvania."
        },
        inspiration: {
            text: `My culinary philosophy has been profoundly shaped by the life and teachings of Chef Anthony Bourdain, whose explorative spirit and intellectual approach to global cultures have greatly influenced my own journey through the world of food. 
            Bourdain’s perspective, encapsulated in his belief that "Food is everything we are," resonates deeply with me. 
            This idea that cuisine reflects our identity—national, ethnic, personal—guides my approach to cooking and living. 
            His series, Parts Unknown, was more than just a travel or cooking show; it was a window to the world, revealing the universal human experience through the lens of food. 
            It taught me how similar we all are, despite our diverse backgrounds, and it inspired me to explore Europe and Asia, experiencing first-hand the connections that can be forged across different cultures through the simple act of sharing a meal.
            
            In the kitchen, my methods are influenced by Chef J. Kenji López-Alt, whose blend of culinary art with scientific principles transforms cooking into an experimental science. 
            López-Alt’s approach to understanding the chemistry of cooking—why a piece of meat sizzles on a hot pan, or how flavors meld and morph under heat—has opened up new avenues for creativity in my own cooking. 
            His work encourages me to experiment and innovate, ensuring that each dish not only tastes good but is crafted with understanding and precision.
            
            Together, these culinary giants have shaped my cooking ethos: to see food as a bridge between cultures and science as a tool to enhance the art of gastronomy. 
            This blend of exploration, understanding, and connection is what I strive to bring to every dish I create.`,
            image: '/images/bourdain-1.webp',
            caption: "Chef Anthony Bourdain in Parts Unknown."
        },
        philosophy: {
            text: `My culinary philosophy is deeply rooted in the belief that "food is love" and that cooking is an act of nourishment. This concept crystallized for me during my experiences with various culinary traditions, particularly my deep dive into Italian cuisine, which has given me a profound appreciation for its depth and elegance.

            However, my culinary journey doesn't stop at Italy. I draw inspiration from my travels around the world, incorporating elements from diverse cultures into my dishes—a reflection of my global culinary experiences. This approach enhances the authenticity of the flavors and deepens my connection to the global community through its diverse culinary heritage. Authenticity in representing and respecting cultures through their culinary traditions is paramount.
            
            I am committed to sourcing the finest ingredients possible, understanding their origins, and ensuring they reflect the principles of quality and respect for the environment. I passionately believe in paying for quality over convenience, and this dedication is evident in every dish I prepare.
            
            Meals are the fundamental part of any gathering, celebration, or simple daily comfort. Food has the unique capacity to bring people together, fostering connections and creating moments to cherish. In every dish I prepare, I aim to honor these moments, crafting meals that not only satiate the appetite but also nourish the soul and express gratitude for life's most significant bonds—those we share with our loved ones.`,
            image: '/images/market.webp',
            caption: "Fresh produce at a local market in Verona, Italy."
        }
    };

    const overviewContent = `
    Jake Yannes, a passionate chef with a deep love for culinary arts, brings together flavors from around the world to create unforgettable dining experiences. 
    Join him as he explores the fusion of traditional and modern cuisines in his quest to nourish and delight.
    `;

    function getTitle(key) {
        switch (key) {
            case 'journey':
                return 'My Culinary Journey';
            case 'holyCow':
                return 'What is Holy Cow Lightning?';
            case 'inspiration':
                return 'My Inspiration';
            case 'philosophy':
                return 'Culinary Philosophy';
            default:
                return '';
        }
    }

    return (
        <div className='storyOverview'>
            <section className='overview' style={{ backgroundImage: `url(/images/kitchenBackground.jpg)`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <div className="story-overview">
                    <img src="/images/chefImage.webp" alt="Chef Jake Yannes" />
                    <div className="story-overview-content">
                        <h1>About Chef Jake</h1>
                        <p>{overviewContent}</p>
                        <div className="decorative-divider"></div>
                        <p className="quote">"Cooking is an expression of the land where you are and the culture of that place." - Wolfgang Puck</p>
                    </div>
                </div>
            </section>

            <div className="detailed-story">
                {Object.keys(content).map((key, index) => (
                    <section className="story-section" key={key}>
                        <h2>{getTitle(key)}</h2>
                        <div className="story-content">
                            <div className={`imageContainer ${index % 2 === 0 ? 'left' : 'right'}`}>
                                <img src={content[key].image} alt={key} className="story-image" />
                                <p className="story-caption">{content[key].caption}</p>
                            </div>
                            <div className="story-text">
                                <p>{content[key].text}</p>
                            </div>
                        </div>
                    </section>
                ))}
            </div>
        </div>
    );
};

export default Story;
