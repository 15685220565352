// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home {
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px;
  }
  
  .home h2 {
    color: #333;
  }

  
  @media (max-width: 768px) {
   .home{
     padding-top: 0px;
   } 
}
  
 `, "",{"version":3,"sources":["webpack://./src/components/Home.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,qBAAqB;EACvB;;EAEA;IACE,WAAW;EACb;;;EAGA;GACC;KACE,gBAAgB;GAClB;AACH","sourcesContent":[".home {\n    text-align: center;\n    padding-top: 100px;\n    padding-bottom: 100px;\n  }\n  \n  .home h2 {\n    color: #333;\n  }\n\n  \n  @media (max-width: 768px) {\n   .home{\n     padding-top: 0px;\n   } \n}\n  \n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
