import React from 'react';
import { useNavigate } from 'react-router-dom';
import './StoryModal.css';

const StoryModal = ({ isOpen, onClose, title, content }) => {
    const navigate = useNavigate();

    if (!isOpen) return null;

    const stopPropagation = (e) => {
        e.stopPropagation();  // Prevent click inside the modal from closing it
    };

    const handleReadMore = () => {
        navigate('/story');  // Adjust the path as necessary
        onClose();  // Close the modal
    };

    return (
        <div className="story-modal-overlay" onClick={onClose}>
            <div className="story-modal-content" onClick={stopPropagation}>
                <h2>{title}</h2>
                <div className="story-modal-body">
                    {content.split('\n').map((paragraph, index) => (
                        <p key={index}>{paragraph}</p>
                    ))}
                </div>
                <div className="story-modal-buttons">
                    <button onClick={handleReadMore} className="story-modal-readmore-btn">Read More</button>
                    <button onClick={onClose} className="story-modal-close-btn">Close</button>
                </div>
            </div>
        </div>
    );
};

export default StoryModal;
